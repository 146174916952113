import { ApolloClient, HttpLink, InMemoryCache } from '@apollo/client';
import fetch from 'isomorphic-fetch';

const client = new ApolloClient({
  link: new HttpLink({
    uri: process.env.GATSBY_WP_GRAPHQL_URL,
    credentials: 'omit',
    fetch,
  }),
  cache: new InMemoryCache({}),
});

export default client;
